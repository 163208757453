let pendingContactId = null as string | null;

export function setContactId(contactId: string) {
  pendingContactId = contactId;
  sendPendingContactId();
}

function sendPendingContactId() {
  if (!pendingContactId) return;
  if (typeof window.va === 'undefined') return;
  window.va('setContactId', pendingContactId);
  pendingContactId = null;
}

export function cart(
  storefrontCart: StorefrontCart,
  locale: string,
  channelRootPath: string,
) {
  if (typeof window.va === 'undefined') {
    console.warn('Voyado Engage is not loaded');
    return;
  }

  if (!locale) {
    console.warn('Locale is required');
    return;
  }

  sendPendingContactId();

  const cartItems = storefrontCart.rows.reduce((acc, row) => `${acc}|${row.articleNumber}:${row.quantity}`, storefrontCart.voyadoCartRef);
  const cartPayload = {
    cartRef: storefrontCart.voyadoCartRef,
    cartUrl: `${window.location.origin}${channelRootPath}?cartRestore=${cartItems}`,
    locale,
    items: storefrontCart.rows.map((row) => ({
      itemId: row.articleNumber,
      quantity: row.quantity,
    })),
  };

  window.va('cart', cartPayload);
}

export function emptyCart(storefrontCart: any) {
  setTimeout(() => {
    if (typeof window.va === 'undefined') {
      console.warn('Voyado Engage is not loaded');
      return;
    }

    sendPendingContactId();
    window.va('emptyCart', {
      cartRef: storefrontCart.voyadoCartRef,
    });
  }, 1000);
}

export function productView(
  itemId: string,
  categoryName: string,
  locale: string,
) {
  if (typeof window.va === 'undefined') {
    console.warn('Voyado Engage is not loaded');
    return;
  }

  sendPendingContactId();

  window.va('productview', {
    itemId,
    locale,
    categoryName,
  });
}

declare global {
  interface Window {
    va: (
      id: 'setContactId' | 'cart' | 'emptyCart' | 'productview',
      val: string | VoyadoCart | VoyadoEmptyCart | VoyadoProductView
    ) => void;
  }
}

type StorefrontCart = {
  voyadoCartRef: string;
  rows: StorefrontCartRow[];
};

type StorefrontCartRow = {
  articleNumber: string;
  quantity: number;
};

type VoyadoCart = {
  cartRef: string;
  contactId?: string;
  cartUrl: string;
  locale: string;
  items: { itemId: string; quantity: number }[];
};

type VoyadoEmptyCart = {
  cartRef: string;
};

type VoyadoProductView = {
  itemId: string;
  contactId?: string;
  locale: string;
  categoryName: string;
};
