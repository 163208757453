<script setup>
import { inject, computed } from 'vue';
import { toLitiumMediaUrlKey } from '@distancify/drapejs-litium/lib/keys';
import { channelKey } from '@/keys';

const channel = inject(channelKey);
const toLitiumMediaUrl = inject(toLitiumMediaUrlKey);

const logoUrl = computed(() => {
  const logo = channel.value?.frameworkContentJson?.CompanyLogo;
  return toLitiumMediaUrl(logo?.id, { maxHeight: 27 }) || '';
});

</script>

<template>
  <div class="checkout-header">
    <div class="checkout-header__content-wrapper">
      <router-link class="checkout-header__logo-container" :to="channel?.rootPath">
        <img :src="logoUrl" class="checkout-header__logo" />
      </router-link>
    </div>
  </div>
</template>

<style>
.checkout-header {
  background-color: var(--color-neutral-00);
  box-shadow: 0px 5px 16px rgba(26, 26, 26, 0.05);
  z-index: 10;
  height: 48px;
  position: fixed;
  width: 100%;
  top: 0;
  border-bottom: 1px solid #edeef0;
}

.checkout-header__content-wrapper {
  width: 100%;
  max-width: var(--layout-maxWidth);
  padding: 0 15px;
  display: flex;
  align-items: center;
  height: 100%;
  margin: auto;
}

.checkout-header__logo-container {
  display: flex;
}

.checkout-header__logo {
  height: 27px;
}

</style>
