import {
  provide,
  InjectionKey,
} from 'vue';

let openModals: any[] = [];

function updateScroll() {
  if (openModals.length) {
    document.body.style.marginRight = `${(
      window.innerWidth - document.body.offsetWidth
    ).toString()}px`;
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.marginRight = '';
    document.body.style.overflow = '';
  }
}

/**
   * Call when opening a modal
   */
export const registerModalKey = <InjectionKey<(key: string) => void>>Symbol('registerModal');
/**
   * Call when closing a modal
   */
export const unregisterModalKey = <InjectionKey<(key: string) => void>>Symbol('unregisterModal');

/**
   * Provides two functions for creating modals that block scrolling, see
   * registerModalKey and unregisterModalKey.
   *
   * The functions will ensure that body scroll is disabled as long as
   * there is at least one modal open, but supports multiple modals at the same time.
   */
export default function () {
  provide(registerModalKey, (key: string) => {
    openModals = openModals.filter((k) => k !== key);
    openModals.push(key);
    updateScroll();
  });

  provide(unregisterModalKey, (key: string) => {
    openModals = openModals.filter((k) => k !== key);
    updateScroll();
  });
}
