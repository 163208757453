<script>
import { computed, ref } from 'vue';
import { useGA4 } from '@distancify-storefront/tracking-gtm';

import useCompareProducts from '@/composables/useCompareProducts';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import useSizeDisplay from '@/composables/useSizeDisplay';

import ColorSwatch from '../ColorSwatch.vue';
import ProductCardMedia from './ProductCardMedia.vue';
import ProductCardPrice from './ProductCardPrice.vue';

const ga4 = useGA4({
  useLegacyEnhancedECommerce: true,
});

const maxVisibleColors = 4;

export default {
  components: {
    ColorSwatch,
    ProductCardMedia,
    ProductCardPrice,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    hideRelatedArticles: {
      type: Boolean,
      default: false,
    },
    sizeFilter: {
      type: Object,
      default: null,
    },
    useOneImage: {
      type: Boolean,
      default: false,
    },
    disableHover: {
      type: Boolean,
      default: false,
    },
    reinitializeImagesOnResize: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    compareProduct: false,
    isMounted: false,
    intersectionObserver: null,
    isContentVisible: false,
  }),
  setup(props) {
    const { websiteText } = useWebsiteTexts();
    const compareProducts = useCompareProducts();
    const { getSizeName } = useSizeDisplay();
    const isCardHovered = ref(false);
    const selectedColor = ref(null);
    const allSizes = computed(() => {
      let sizes = props.product?.sizes;
      
      if (selectedColor.value) {
        sizes = selectedColor.value.sizes;
      }

      return sizes?.map((s) => ({ ...s, caption: getSizeName(s.caption) })) || [];
    });

    const sizeRange = computed(() => {
      if (!allSizes.value.length) {
        return '';
      }
      if (allSizes.value.length == 1) {
        return `${websiteText('productcard__size_range').value || 'productcard__size_range'} ${
          allSizes.value[0].caption
        }`;
      }
      return `${websiteText('productcard__size_range').value || 'productcard__size_range'} ${
        allSizes.value[0].caption
      }-${allSizes.value[allSizes.value.length - 1].caption}`;
    });

    function onCardMouseOver() {
      isCardHovered.value = props.disableHover ? false : true;
    }

    function onCardMouseLeave() {
      isCardHovered.value = false;
    }

    return {
      websiteText,
      compareProducts,
      selectedColor,
      allSizes,
      isCardHovered,
      sizeRange,
      reinitializeImagesOnResize: props.reinitializeImagesOnResize,
      onCardMouseOver,
      onCardMouseLeave,
    };
  },
  computed: {
    currentVariant() {
      const variants = this.product?.variants || [];
      for (const variant of variants) {
        if (variant.articleNumber.toLowerCase() === this.product.articleNumber.toLowerCase()) {
          return variant;
        }
      }
      return null;
    },
    variantQueryParams() {
      let urlSuffix = '';
      if (this.currentVariant) {
        const suffixParts = [
          {
            key: 'size',
            value: this.currentVariant?.size || '',
          },
          {
            key: 'color',
            value: (this.currentVariant?.color || []).join(','),
          },
        ].filter((q) => q.value);
        if (suffixParts.length) {
          urlSuffix = suffixParts.reduce((p, { key, value }) => `${p}${key}=${value}&`, '?').slice(0, -1);
        }
      }
      return urlSuffix;
    },
    productUrl() {
      if (this.selectedColor) {
        return this.selectedColor.url + this.variantQueryParams;
      }

      return (this.product?.relativeURL || this.product?.url || this.product.href) + this.variantQueryParams || '';
    },
    productTag() {
      return this.product?.productCardTag?.name || '';
    },
    productName() {
      return this.product?.productName || this.product?.name || '';
    },
    currentPrice() {
      return this.product.campaignPrice ? this.product.campaignPrice : this.product.price;
    },
    originalPrice() {
      if (this.product.campaignPrice && this.product.price > this.product.campaignPrice) {
        return this.product.price;
      }
      return 0;
    },
    articleNumber() {
      return this.product?.articleNumber || '';
    },
    modelNumber() {
      return this.product?.modelNumber || '';
    },
    colorName() {
      return this.product?.colorName || '';
    },
    currency() {
      return this.$cart?.currency?.id;
    },
    locale() {
      return this.$channel?.locale;
    },
    decimals() {
      return this.$cart?.currency?.currencyDecimals || 0;
    },
    images() {
      if (this.product.imageIds?.length && !this.useOneImageInCarousel) {
        return this.product.imageIds.map((i) => this.$toLitiumMediaUrl(i, { maxHeight: 650 }));
      }
      return [this.$toLitiumMediaUrl(this.product.image, { maxHeight: 650 })];
    },
    selectedColorImage() {
      const imageId = this.selectedColor?.imageId;
      if (!imageId) return null;
      return this.$toLitiumMediaUrl(imageId, { maxHeight: 650 });
    },
    variants() {
      return this.product?.variants || [];
    },
    otherVariants() {
      return this.variants.filter((v) => v.articleNumber != this.articleNumber);
    },
    colors() {
      const colorMap = this.$metaProduct?.fieldsJson?.ProductCardColorMap;
      if (!colorMap || !this.product.colorOptions) return [];

      return this.product.colorOptions
        .filter((op) => {
          if (!this.sizeFilter || !this.sizeFilter.length) return true;
          return op.sizes.find((s) => this.sizeFilter.includes(s.caption) && s.inStock) || false;
        }).reduce((acc, op) => {
          const cssColor = colorMap[op.colorCode];
          if (!cssColor) return acc;

          const colorOption = {
            code: op.colorCode,
            cssColor,
            active: this.product.colorCode === op.colorCode,
            url: op.url,
            imageId: op.imageId,
            sizes: op.sizes,
          };

          if (this.product.colorCode === op.colorCode && acc.length > 4) {
            acc.splice(4, 0, colorOption);
          } else {
            acc.push(colorOption);
          }

          return acc;
        }, []);
    },
    extraColors() {
      return this.colors.length - maxVisibleColors;
    },
    category() {
      return this.$page.dataJson?.category;
    },
    hidePrices() {
      const ecommerceFeaturesEnabled = this.$cart?.ecommerceFeaturesEnabled || false;
      if (!ecommerceFeaturesEnabled) {
        return true;
      }
      const hidePrices = this.$channel?.hidePricesForAnonymousUsers || false;
      return hidePrices ? !this.$user?.isAuthenticated : false;
    },
    useOneImageInCarousel() {
      return this.useOneImage;
    },
  },
  methods: {
    pushSelectItemEvent() {
      const item = {
        item_id: this.modelNumber,
        item_name: this.productName,
        item_color_id: this.articleNumber,
        item_color: this.colorName,
      };

      if (this.category) {
        item.item_list_id = `category_${this.category.systemId}`;
        item.item_list_name = `Category: ${this.category.googleAnalyticsName}`;
      }

      ga4.selectItem(item);
    },
    selectColorOption(color) {
      if (this.product.colorCode !== color.code) {
        this.selectedColor = color;
      } else {
        this.selectedColor = null;
      }
    },
  },
  mounted() {
    this.isMounted = true;

    const options = {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0.1,
    };

    this.intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isContentVisible = true;
        } else {
          this.isContentVisible = false;
        }
      });
    }, options);

    this.intersectionObserver.observe(this.$refs.productCardElRef.$el);
  },
  beforeUnmount() {
    this.intersectionObserver?.disconnect();
  },
};
</script>

<template>
  <router-link
    :to="productUrl"
    class="product-card"
    ref="productCardElRef"
    @click="pushSelectItemEvent()">
    <div class="product-card__content">
      <div 
        class="product-card__product-media"
        @mouseover="onCardMouseOver"
        @mouseleave="onCardMouseLeave"
      >
        <div
          class="product-card__image-container">
          <div v-if="selectedColorImage" class="product-card__selected-color-overlay-background"></div>
          <img v-if="selectedColorImage" :src="selectedColorImage" class="product-card__selected-color-overlay"/>
          <div v-if="selectedColorImage" class="product-card__selected-color-overlay-shadow"></div>
          <ProductCardMedia 
            :images="images" 
            :is-card-hovered="isCardHovered" 
            :reinitialize-images-on-resize="reinitializeImagesOnResize" 
          />
        </div>
      </div>
      <div v-if="isContentVisible" class="product-card__info">
        <template v-if="!isCardHovered">
          <div
            v-if="productTag"
            class="product-card__product-tag">
            {{ productTag }}
          </div>
          <h2 class="product-card__name">{{ productName }}</h2>
          <ProductCardPrice
            v-if="!hidePrices"  
            :originalPrice="originalPrice"
            :currentPrice="currentPrice"
          />
          <div v-else class="product-cart__price-placeholder"></div>
        </template>
        <template v-if="isCardHovered">
          <div class="product-card__sizes-caption">
            {{ websiteText('productcard__sizes_caption').value || 'productcard__sizes_caption' }}
          </div>
          <div class="product-card__sizes">
            <div
              class="product-card__size"
              :class="{ 'product-card__size--out-of-stock': !size.inStock }"
              v-for="size in allSizes"
              :key="size.caption">
              <span>{{ size.caption }}</span>
            </div>
          </div>
        </template>
        <div :class="['product-card__colors']" @click.prevent.stop="">
          <ColorSwatch
            v-for="color in colors.slice(0, 4)"
            :key="color.cssColor"
            :color="color.cssColor"
            :active="!selectedColor ? color.active : color.code === selectedColor.code"
            @click.prevent.stop="selectColorOption(color)"
            class="product-card__color-swatch"
          />
          <div v-if="extraColors > 0" class="product-card__colors-extra">
            {{ `+${extraColors}` }}
          </div>
        </div>
        <ProductCardPrice
          v-if="!hidePrices && isCardHovered" 
          :originalPrice="originalPrice"
          :currentPrice="currentPrice"
        />
        <div v-if="hidePrices && isCardHovered" class="product-cart__price-placeholder"></div>
        <div
          :class="['product-card__size-range', { 'product-card__size-range--extra-margin': !productTag }]"
          @click.prevent.stop=""
          v-if="allSizes.length > 0 && !isCardHovered">
          {{ sizeRange }}
        </div>
      </div>
      <div
        class="product-card__info--shell"
        v-if="!isContentVisible"></div>
    </div>
  </router-link>
</template>

<style>
  .product-card {
    text-decoration: none;
    background-color: white;
    user-select: none;
    cursor: default;
  }

  .product-card__product-media {
    cursor: pointer;
  }

  .product-card__content:hover .product-card-media__carousel-nav:not(:hover) {
    background: var(--color-transparent-black);
  }

  .product-card-media__carousel-nav:hover {
    background: var(--color-transparent-black-2);
  }

  .product-card__product-media:hover .product-card-media__overlay {
    background: var(--color-transparent-black);
    user-select: none;
  }

  .product-card__image-container {
    overflow: hidden;
    position: relative;
    background-color: white;
  }

  .product-card__selected-color-overlay-background {
    background-color: white;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .product-card__selected-color-overlay {
    position: absolute;
    height: 100%;
    z-index: 2;
    object-fit: contain;
    object-position: center;
  }
  
  .product-card__selected-color-overlay-shadow {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: var(--box-shadow-overlay);
    mix-blend-mode: darken;

  }

  .product-card__product-tag {
    font-size: 10px;
    line-height: 22px;
    font-size: 10px;
    letter-spacing: 0.14em;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
    color: var(--color-black);
    font-family: var(--font-proxima-nova-medium);
  }

  .product-cart__price-placeholder {
    height: 20px;
  }

  .product-card__sizes {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 2px 0;
  }

  .product-card__size {
    font-size: 14px;
    font-family: var(--font-proxima-nova-light);
    color: var(--color-black);
  }

  .product-card__sizes-caption,
  .product-card__size-range {
    font-size: 14px;
    font-family: var(--font-proxima-nova-light);
    line-height: 20px;
    color: var(--color-black);
  }

  .product-card__size-range {
    cursor: default;
  }

  .product-card__size--out-of-stock {
    color: var(--color-storm-grey);
    position: relative;
  }

  .product-card__size--out-of-stock::after{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 1px; 
    background:  var(--color-storm-grey);
    top: 55%; 
    transform: translateY(-50%);
  }

  .product-card__colors {
    display: flex;
    cursor: default;
    margin-top: auto;
    align-items: center;
    margin-bottom: 12px;
  }

  .product-card__colors-extra {
    height: 13px;
    line-height: 13px;
    color: var(--color-text-standard);
    font-size: 12px;
    padding-left: 6px;
  }

  .product-card__color-swatch {
    padding-left: 6px;
    padding-right: 6px;
  }

  .product-card__color-swatch:first-child {
    padding-left: 0;
  }

  .product-card__image-incoming {
    opacity: 0.5;
  }

  .product-card__info {
    padding-top: 12px;
    padding: 12px 15px 0px 15px;
    background-color: white;
    line-height: 18px;
    height: 134px;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .product-card__info--shell {
    height: 134px;
  }

  .product-card__name {
    font-size: 14px;
    color: var(--color-black);
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: var(--font-proxima-nova-light);
    margin: 0;
    line-height: 20px;
  }

  @media (--tabletAndDesktop) {
    .product-card__product-tag {
      font-size: 12px;
      letter-spacing: 0.15em;
    }

    .product-card__name {
      line-height: 22px;
    }
    .product-card__sizes-caption,
    .product-card__size-range {
      line-height: 22px;
    }

    .product-card__color-swatch {
      padding-left: 4px;
      padding-right: 4px;
    }

    .product-card__color-swatch:first-child {
      padding-left: 0;
    }

    .product-card__colors {
      margin-bottom: 8px;
    }

    .product-card__colors-extra {
      height: 15px;
      line-height: 15px;
      font-size: 14px;
      padding-left: 6px;
    }

    .product-cart__price-placeholder {
      height: 22px;
    }
  }
</style>
