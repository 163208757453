import { query, request } from '@distancify/drapejs-litium';
import { defineCommand, defineReceiver } from '@drapejs/invoker'
import { gql } from 'graphql-request';

export const getCompareProductData = defineCommand<{
  url: string;
  baseProductNumbers: string[];
}>('getCompareProductData');

export const getCompareProductDataReceiver = defineReceiver(getCompareProductData, async function (command, data) {
  const result = await request(this.cache, query(gql`
    query fetchCompareProducts($url: String!, $baseProductNumbers: [String]!) {
      session(url: $url) {
          compareProductData(baseProductNumbers: $baseProductNumbers)
      }
    }
  `), command);

  return result;
}, 'litium');