import { InjectionKey, Ref } from 'vue';
import { Emitter } from 'mitt';

export const activeDesktopFlyoutKey: InjectionKey<{ isPinned: boolean; uid: string | null}> = Symbol('activeDesktopFlyout');
export const replaceTokensKey: InjectionKey<(text: string, args?: { [key: string]: string }) => string> = Symbol('replaceTokens');
export const emitterKey: InjectionKey<Emitter<any>> = Symbol('mitt');
export const cartKey: InjectionKey<Ref<any>> = Symbol('cart');
export const countrySwitcherKey: InjectionKey<Ref<any>> = Symbol('countrySwitcher');
export const isSearchOpenKey: InjectionKey<Ref<boolean>> = Symbol('isSearchOpen');
export const searchStringKey: InjectionKey<Ref<string>> = Symbol('searchStringKey');
export const searchResultKey: InjectionKey<Ref<any>> = Symbol('searchResultKey');
export const channelKey: InjectionKey<Ref<any>> = Symbol('channel');
export const userKey: InjectionKey<Ref<any>> = Symbol('user');
export const frameworkContentKey: InjectionKey<Ref<{ checkoutUrl: string; MaterialBankPage: any }>> = Symbol('frameworkContent');
export const litiumKey: InjectionKey<Ref<any>> = Symbol('litium');
