export function validateEmail(email: string) {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
}

export function getParameterByName(name: string, url = window.location.href) {
  const n = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&] ${n} (=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function buildQueryParams(query: any) {
  const querySegments: any[] = [];

  for (const parameter in query) {
    const value = query[parameter];
    if (value) {
      querySegments.push(`${parameter}=${value}`);
    }
  }

  return querySegments.join('&');
}

export function buildUrlPathWithQuery(path: string, query: any) {
  try {
    const queryParams = buildQueryParams(query);
    return queryParams ? `${path}?${queryParams}` : path;
  } catch (err) {
    console.error(err);
    return path;
  }
}

const whitelistedParams = ['color', 'size', 'action', 'utm', 'gclid', 'fbclid'];

export function buildChannelSwitchUrlPathWithQuery(path: string, query: any) {
  try {
    const querySegments: any[] = [];
    for (const parameter in query) {
      if (isWhitelistedParameter(parameter)) {
        const value = query[parameter];
        if (value) {
          querySegments.push(`${parameter}=${value}`);
        }
      }
    }

    const queryParams = querySegments.join('&');
    return queryParams ? `${path}?${queryParams}` : path;
  } catch (err) {
    console.error(err);
    return path;
  }

  function isWhitelistedParameter(parameter: string) {
    return whitelistedParams.find((p) => p === parameter);
  }
}

export function clamp(val, max, min = 0) {
  return Math.max(Math.min(max, val), min);
}

export const debounce = (callback: any, wait: number) => {
  let timeoutId: number | null = null;
  return (...args: any[]) => {
    if (timeoutId !== null) {
      window.clearTimeout(timeoutId);
    }
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

export function getPrefferedCountryCookieValue() {
  const cookies = getCookies();
  return cookies['PrefferedCountry'];
}

export function setPrefferedCountryCookie(countryId) {
  const aYearFromNow = new Date();
  aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

  var dateString = aYearFromNow.toUTCString();
  document.cookie = `PrefferedCountry=${countryId};Path=/;Expires=${dateString};`;
}

export function getCookies() {
  const REGEXP = /([\w\.]+)\s*=\s*(?:"((?:\\"|[^"])*)"|(.*?))\s*(?:[;,]|$)/g;
  let cookies = {};
  let match;
  while ((match = REGEXP.exec(document.cookie)) !== null) {
    let value = match[2] || match[3];
    cookies[match[1]] = decodeURIComponent(value);
  }
  return cookies;
}

export const convertToIsoDate = (isoString: string) => {
  const date = new Date(isoString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

