<script>
import useCompareProducts from '@/composables/useCompareProducts';
import { litiumBaseUrl } from '@/config';
import CompareViewVisualInfo from './CompareViewVisualInfo.vue';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

let normalizedLitiumBaseUrl = litiumBaseUrl;
if (litiumBaseUrl[litiumBaseUrl.length - 1] === '/') {
  normalizedLitiumBaseUrl = normalizedLitiumBaseUrl.substring(0, normalizedLitiumBaseUrl.length - 1);
}

// Intentionally creating a new regex instance everytime to avoid sporadic null results
const hashExtractRegex = () => /compare=([^\&#]+)/gi;

function getProductsFromHashStr(str) {
  if (!str) return null;

  const result = hashExtractRegex().exec(str);
  if (!result?.[1]) return null;
  return result[1].split(',').map((f) => f.trim());
}

function removeCompareFromHash(str) {
  let cleanStr = str && str.replace('#', '') || '';
  const result = hashExtractRegex().exec(cleanStr);

  if (result) {
    cleanStr = cleanStr.replace(result[0], '');
  }

  if (cleanStr.length > 0) {
    if (cleanStr[cleanStr.length - 1] === '&') {
      cleanStr = cleanStr.slice(0, -1);
    }
    return `#${cleanStr}`;
  }

  return cleanStr;
}

function prepareHashString(str, products) {
  let prefix = removeCompareFromHash(str) || '';

  if (prefix.length > 0) {
    prefix += '&';
  } else {
    prefix = '#';
  }

  if (products.length > 0) {
    return `${prefix}compare=${products.join(',')}`;
  }

  return prefix.slice(0, -1);
}

export default {
  components: {
    CompareViewVisualInfo,
  },
  data: () => ({
    compactedView: false,
    originalBodyOverflow: null,
    currentlyOpenVisualScalePopup: null,
    useNoSnap: false,
    disableHashUpdateOnce: false,
  }),
  setup() {
    const compareProducts = useCompareProducts();
    const { websiteText } = useWebsiteTexts();

    return {
      compareProducts,
      websiteText,
    };
  },
  computed: {
    products() {
      return this.compareProducts.loadedProductsData.value;
    },
    onlyOneProduct() {
      return this.products.length === 1;
    },
    opened() {
      if (typeof document === 'undefined') return false;

      const opened = this.compareProducts.opened.value;
      document.body.style.overflow = opened ? 'hidden' : this.originalBodyOverflow;

      if (opened) {
        this.compareProducts.fetchProducts();
      }

      return opened;
    },
    printUrl() {
      const comparePrintUrl = this.compareProducts.comparePrintUrl.value;
      if (!comparePrintUrl) return null;

      return `${comparePrintUrl}?products=${this.products.map((p) => `${p.baseProductNumber}`).join(',')}`;
    },
  },
  methods: {
    close(disableHashUpdate = false) {
      this.disableHashUpdateOnce = disableHashUpdate;
      this.compareProducts.toggleOpen();
    },
    removeProduct(baseProductNumber) {
      this.compareProducts.remove(baseProductNumber);
    },
    scroll() {
      this.compactedView = this.$refs.compareViewTable.scrollTop >= this.$refs.firstRow.offsetHeight;
    },
    openVisualScalePopup(product, visualScale) {
      this.currentlyOpenVisualScalePopup = `${product.baseProductNumber}_${visualScale.ConnnectedProperty}`;
    },
    closeVisualScalePopup(e) {
      if (e.target !== e.currentTarget) {
        return;
      }

      this.currentlyOpenVisualScalePopup = null;
    },
    shouldShowScalePopup(product, visualScale) {
      return this.currentlyOpenVisualScalePopup === `${product.baseProductNumber}_${visualScale.ConnnectedProperty}`;
    },
    getImageUrl(imageId, options) {
      return this.$toLitiumMediaUrl(imageId, options);
    },
    updateHash() {
      if (this.disableHashUpdateOnce) {
        this.disableHashUpdateOnce = false;
        return;
      }
      const { hash } = window.location;
      const hrefWithoutHash = this.$route.href.replace(`#${this.$route.hash}`, '');
      if (this.opened) {
        window.history.replaceState({}, document.title, hrefWithoutHash + prepareHashString(hash, this.products.map((p) => p.baseProductNumber)));
      } else if (getProductsFromHashStr(hash) !== null) {
        window.history.replaceState({}, document.title, hrefWithoutHash + removeCompareFromHash(hash));
      }
    },
  },
  watch: {
    opened(newValue) {
      this.updateHash();
      if (newValue) {
        this.compactedView = false;
      }

      this.$nextTick(() => {
        if (newValue) {
          // TODO: maybe use vue-multi-ref
          this.$refs.compareViewTable.addEventListener('scroll', this.scroll.bind(this));
        }
      });
    },
    products() {
      this.updateHash();
    },
  },
  mounted() {
    const products = getProductsFromHashStr(window.location.hash);
    if (products !== null && products.length) {
      this.compareProducts.replaceProducts(products);
      this.compareProducts.toggleOpen();
    }

    this.compareProducts.fetchProducts();

    this.useNoSnap = isIOSDevice();
    this.originalBodyOverflow = document.body.style.overflow;
    window.addEventListener('scroll', () => {
      if (this.opened && this.$refs.compareView) {
        if (this.$refs.compareView.scrollTop <= 0) {
          this.$refs.compareView.scrollIntoView(true);
        } else {
          this.$refs.compareView.scrollIntoView(false);
        }
      }
    });

    function isIOSDevice() {
      return navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    }
  },
};
</script>

<template>
  <div
    class="compare-view"
    :class="{ 'compare-view-nosnap': useNoSnap }"
    v-if="opened"
    ref="compareView"
  >
    <div class="compare-view-headerWrapper">
      <div class="compare-view-header">
        <div class="compare-view-headerSpacer">
          <div class="compare-view-closeButton" @click="close()">&times;</div>
        </div>
        <h1 class="compare-view-title">
          {{ websiteText('compare_compare').value }}
        </h1>
        <div class="compare-view-headerSpacer compare-view-headerSpacer--left">
          <a class="compare-view-printButton" target="_blank" v-if="printUrl" :href="printUrl">
            <img src="/icons/icon_print.svg" />
          </a>
        </div>
      </div>
    </div>
    <div class="compare-view-tableWrapper" ref="compareViewTable">
      <div class="compare-view-tableWrapper__container">
        <div class="compare-view-headerSticky">
          <div
            v-for="product in products"
            :key="product.baseProductNumber"
            class="compare-view-productHeaderCompactedWrapper"
            :class="{ 'compare-view-productHeaderTop': compactedView }"
          >
            <div class="compare-view-productHeaderCompacted">
              <router-link :to="product.productUrl" @click="close(true)" class="compare-view-imageWrapperCompacted">
                <img :src="getImageUrl(product.imageId, { fillArea: { width: 544, height: 724 } })" class="compare-view-image" />
              </router-link>
              <div class="compare-view-detailsCompacted">
                <a
                  :href="product.productUrl"
                  class="compare-view-heading compare-view-productNameCompacted"
                >{{ product.name }}</a>
                <div class="compare-view-colorPrice">
                  <span class="compare-view-colorCompacted">{{ product.color }}</span>
                  <h2 class="compare-view-priceCompacted">{{ product.price }}</h2>
                </div>
              </div>
              <div
                class="compare-view-removeProductButton compare-view-removeProductButtonCompacted"
                @click="removeProduct(product.baseProductNumber)"
              >
                <i class="fa fa-trash"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="compare-view-row" ref="firstRow">
          <div
            v-for="product in products"
            :key="product.baseProductNumber"
            class="compare-view-imageRow compare-view-column"
          >
            <div
              class="compare-view-removeProductButton"
              @click="removeProduct(product.baseProductNumber)"
            >
              <i class="fa fa-trash"></i>
            </div>
            <router-link :to="product.productUrl" @click="close(true)" class="compare-view-imageWrapper">
              <img :src="getImageUrl(product.imageId, { fillArea: { width: 544, height: 724 } })" class="compare-view-image" />
            </router-link>
          </div>
          <div
            v-if="onlyOneProduct"
            class="compare-view-messageColumn"
          >
            {{ websiteText('compare_onlyoneproductadded').value }}
          </div>
        </div>
        <div class="compare-view-row">
          <div v-for="product in products" :key="product.baseProductNumber" class="compare-view-column">
            <a
              :href="product.ProductUrl"
              class="compare-view-heading compare-view-productTitle"
            >{{ product.name }}</a>
            <span class="compare-view-productColor">{{ product.color }}</span>
            <span class="compare-view-productPrice">{{ product.price }}</span>
          </div>
        </div>
        <div class="compare-view-row">
          <div v-for="product in products" :key="product.baseProductNumber" class="compare-view-column">
            <div class="compare-view__visual-scales">
              <CompareViewVisualInfo :info="product.fitInfo" class="compare-view__visual-scales-item"></CompareViewVisualInfo>
              <CompareViewVisualInfo :info="product.shapeInfo" class="compare-view__visual-scales-item"></CompareViewVisualInfo>
            </div>
          </div>
        </div>
        <div class="compare-view-row">
          <div v-for="product in products" :key="product.baseProductNumber" class="compare-view-column">
            <h1 class="compare-view-heading">
              {{ websiteText('compare_sustainability').value }}
            </h1>
            <ul class="compare-view-listing">
              <li
                v-for="item in product.sustainability"
                :key="item.value"
                class="compare-view-listingElement"
              >{{ item.title }}</li>
            </ul>
          </div>
        </div>
        <div class="compare-view-row">
          <div
            style="padding: 0"
            v-for="product in products"
            :key="product.baseProductNumber"
            class="compare-view-column"
          >
            <hr
              class="compare-view-divider"
              v-if="product.visualScales && product.visualScales.length > 0"
            />
          </div>
        </div>
        <div class="compare-view-row">
          <div v-for="product in products" :key="product.baseProductNumber" class="compare-view-column">
            <div class="compare-view__visual-scales">
              <div
                v-for="scale in product.visualScales"
                :key="scale.connnectedProperty"
                class="compare-view__visual-scales-item"
              >
                <div class="compare-view__visual-scales-itemHeadingCompare">
                  {{ scale.heading }}
                  <span
                    class="compare-view__visual-scales-itemInfo"
                    @click="openVisualScalePopup(product, scale)"
                  ></span>
                </div>
                <img class="compare-view__visual-scales-image" :src="getImageUrl(scale.scaleImageId)" />
                <div>{{ scale.scaleShortDescription }}</div>

                <div
                  class="compare-view__visual-scales-popup"
                  v-show="shouldShowScalePopup(product, scale)"
                  @click="closeVisualScalePopup"
                >
                  <div class="compare-view__visual-scales-popupContent">
                    <div class="compare-view__visual-scales-popupHeading">
                      {{ scale.infoHeading }}
                      <span
                        class="compare-view__visual-scales-popupClose"
                        @click="closeVisualScalePopup"
                      >×</span>
                    </div>
                    <div class="compare-view__visual-scales-popupDescription" v-html="scale.infoText"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="compare-view-row">
          <div
            style="padding: 0"
            v-for="product in products"
            :key="product.baseProductNumber"
            class="compare-view-column"
          >
            <hr class="compare-view-divider" />
          </div>
        </div>

        <div class="compare-view-row compare-view-lastRow">
          <div v-for="product in products" :key="product.baseProductNumber" class="compare-view-column">
            <h1 class="compare-view-heading">
              {{ websiteText('compare_functions').value }}
            </h1>
            <ul class="compare-view-listing">
              <li
                v-for="func in product.functions"
                :key="func"
                class="compare-view-listingElement"
              >{{ func }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.compare-view {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--color-white);
  z-index: 100;
  height: 100vh;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  pointer-events: all;
}

.compare-view-headerWrapper {
  width: 100%;
}

.compare-view-header {
  display: flex;
  margin: 0 auto;
  max-width: 600px;
}

.compare-view-closeButton {
  font-size: 45px;
  padding: 5px;
  cursor: pointer;
  display: inline-block;
  flex: 1 1 auto;
}

.compare-view-printButton {
  font-size: 45px;
  padding: 5px;
  padding-top: 3px;
  cursor: pointer;
  display: inline-block;
  flex: 1 1 auto;
}

.compare-view-title {
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
}

.compare-view-headerSpacer {
  flex: 1;
}
.compare-view-headerSpacer--left {
  text-align: right;
}
.compare-view-tableWrapper {
  display: flex;
  width: 100%;
  overflow: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 85px);
  position: relative;
}

.compare-view-tableWrapper__container {
  margin: auto;
}

.compare-view-row {
  display: flex;
}

.compare-view-lastRow {
  padding-bottom: 60px;
}

.compare-view-column {
  width: 250px;
  min-width: 250px;
  padding: 10px;
  scroll-snap-align: center;
}

.compare-view-nosnap .compare-view-tableWrapper {
  scroll-snap-type: none;
}

.compare-view-nosnap .compare-view-column {
  scroll-snap-align: none;
}

.compare-view-productHeader {
  width: 160px;
  padding: 0;
  position: relative;
}

.compare-view-removeProductButton {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--color-coldGrey100);
  background-image: url(/icons/icon_nav_close.svg);
  background-position: center center;
  background-size: 80%;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.compare-view-imageRow {
  position: relative;
  vertical-align: middle;
  padding: 0;
}

.compare-view-image {
  max-width: calc(100% - 1rem);
  width: calc(250px - 1rem);
  margin: 0.5rem;
  max-height: 312px;
  object-fit: contain;
}

.compare-view-imageWrapperCompacted .compare-view-image {
  max-height: 45px;
}

.compareView-divider {
  border-top: 1px solid #edeef0;
  width: 90%;
  margin: 0 auto;
}

.compare-view-heading {
  display: block;
  font-size: 21px;
  text-decoration: none;
  font-family: var(--font-header);
  margin: 0 0 0.75em;
  color: var(--color-text-standard);
}

.compare-view-heading:hover {
  color: var(--color-text-standard);
}

.compare-view-productTitle {
  margin-bottom: 0;
}

.compare-view-productColor {
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 700;
}

.compare-view-productPrice {
  margin-bottom: 0;
  font-size: 17px;
  color: var(--color-black);
}

.compare-view-listing {
  list-style-position: outside;
  margin-left: 15px;
}

.compare-view-listingElement {
  padding-left: 10px;
  line-height: 1.5em;
}

.compare-view-headerSticky {
  position: sticky;
  top: 0;
  height: 0;
  z-index: 200;
  white-space: nowrap;
}

.compare-view-productHeaderCompactedWrapper {
  position: relative;
  height: 85px;
  display: inline-block;
  width: 250px;
  pointer-events: none;
}

.compare-view-productHeaderTop.compare-view-productHeaderCompactedWrapper {
  pointer-events: auto;
}

.compare-view-productHeaderCompacted {
  display: flex;
  position: absolute;
  bottom: 0;
  height: 85px;
  width: 100%;
  background-color: var(--color-white);
  transform: translateY(-250%);
  transition: transform ease-in-out 0.3s;
}

.compare-view-productHeaderTop .compare-view-productHeaderCompacted {
  padding-top: 10px;
  transform: translateY(-2%);
}

.compare-view-imageWrapperCompacted {
  display: block;
  width: 20%;
}

.compare-view-detailsCompacted {
  width: 70%;
  text-align: left;
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.compare-view-productNameCompacted {
  font-size: 16px;
  line-height: 1.2em;
  white-space: normal;
  margin: 0;
}

.compare-view-colorPrice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.compare-view-colorCompacted {
  white-space: normal;
  line-height: 1.1em;
}

.compare-view-priceCompacted {
  font-size: 18px;
  margin: 0;
}

.compare-view-removeProductButtonCompacted {
  top: 10px;
}

.compare-view-messageColumn {
  display: flex;
  width: calc(250px - 1rem);
  min-width: calc(250px - 1rem);
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f0f0f0;
  padding: 30px;
  margin: 10px 10px 14px 10px;
}

.compare-view__visual-scales {
    margin: 1rem auto
}

.compare-view__visual-scales-item {
    margin: 2rem auto;
    text-align: center;
    color: var(--color-black);
}

.compare-view__visual-scales-itemHeading {
    font-size: 25px;
    font-family: var(--font-header);
}

.compare-view__visual-scales-itemHeadingCompare {
    font-size: 21px;
    font-family: var(--font-header);
}

.compare-view__visual-scales-itemInfo {
    display: inline-block;
    background-image: url(/icons/icon_info.svg);
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 8px;
    width: 24px;
    height: 22px;
    margin: 8px 0 0;
    cursor: pointer;
}

.compare-view__visual-scales-image {
    margin: 1rem 0;
    height: 8rem;

    @media (--tabletAndDesktop) {
        height: 6rem;
    }
}

.compare-view__visual-scales-popup {
    position: fixed;
    z-index: 300;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
}

.compare-view__visual-scales-popupHidden {
    display: none;
}

.compare-view__visual-scales-popupContent {
    margin: 15% auto;
    padding: 1rem;
    border: 1px solid var(--color-grey500);
    max-width: 520px;
    background-color: var(--color-white);
    color: var(--color-black);
}

.compare-view__visual-scales-popupContent b, .compare-view__visual-scales-popupContent strong {
    font-weight: bold;
}

.compare-view__visual-scales-popupHeading {
    font-family: var(--font-header);
    font-size: 25px;
    text-align: left;
    line-height: 45px;
}

.compare-view__visual-scales-popupDescription {
    text-align: left;
}

.compare-view__visual-scales-popupClose {
    float: right;
    font-family: var(--font-body);
    font-size: 45px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
}

</style>
