<script>
import useCompareProducts, { productCount } from '@/composables/useCompareProducts';

export default {
  props: ['title'],
  setup() {
    const compareProducts = useCompareProducts();
    return {
      compareProducts,
    };
  },
  computed: {
    count() {
      return productCount.value;
    },
    isActive() {
      return this.count > 0;
    },
    comparePosition() {
      return this.isActive ? '90px' : '0px';
    },
    comparePadding() {
      return this.isActive ? '0.5rem' : '0rem 0.5rem';
    },
    compareBorder() {
      return this.isActive ? '1px' : '0px';
    },
    comparePointerEvents() {
      return this.isActive ? 'all' : 'none';
    },
  },
  methods: {
    open() {
      this.compareProducts.toggleOpen();
    },
    clear() {
      this.compareProducts.clear();
    },
  },
};
</script>

<template>
  <div
    class="compare-notification"
    :style="{
      borderTopWidth: compareBorder,
      maxHeight: comparePosition,
      padding: comparePadding,
      pointerEvents: comparePointerEvents,
    }">
    <div class="compare-notification__clear-button" @click="clear()">×</div>
    <div class="compare-notification__main-button" @click="open()">{{ title }} ( {{ count }} )</div>
  </div>
</template>

<style>
.compare-notification {
    background-color: white;
    border-top-color: #edeef0;
    border-top-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: max-height ease-in-out 0.2s, padding ease-in-out 0.2s;
    overflow: hidden;
}

.compare-notification__main-button,
.compare-notification__clear-button {
    padding: .5rem;
    background-color: var(--color-white);
    border: 1px solid var(--color-coldGrey400);
    color: var(--color-black);
    font-family: var(--font-header);
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    user-select: none;
}
.compare-notification__clear-button {
    margin-right: 1.5rem;
    display: inline-block;
    font-family: var(--font-body);
    font-size: 45px;
    border: 0;
}

.compare-notification__main-button {
    display: inline-block;
    min-width: 200px;
    width: 20%;
}
</style>
