<script>
export default {
};
</script>
<template>
  <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1225_13483)">
      <path
        d="M1.11594 17.1899L8.95256 9.56243C9.01943 9.49736 9.05772 9.40387 9.05772 9.30567C9.05772 9.20746 9.01943 9.11397 8.95256 9.0489L1.11594 1.4207"
        stroke="white"
        stroke-width="1.82718"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1225_13483"
        x="0.478699"
        y="0.766052"
        width="9.49261"
        height="17.9922"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.913588" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1225_13483" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1225_13483"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
<style>
</style>
