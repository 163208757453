<script>
import { OptionsComponent } from '@drapejs/core';
import SearchViewInput from './SearchViewInput.vue';
import SearchViewDesktopResults from './SearchViewDesktopResults.vue';
import { searchResultKey, isSearchOpenKey } from '@/keys';

export default {
  extends: OptionsComponent,
  components: {
    SearchViewInput,
    SearchViewDesktopResults,
  },
  inject: {
    searchResult: searchResultKey,
    isSearchOpen: isSearchOpenKey,
    headerState: 'headerState',
  },
  data: () => ({
    isMouseInView: false,
    isSearchViewOpen: false,
  }),
  watch: {
    isSearchOpen(val) {
      if (val) {
        window.addEventListener('click', this.closeOnClickOutside);
        this.isSearchViewOpen = true;
        this.$nextTick(() => {
          this.$mitt.emit('focus-search-input');
        });
      } else {
        window.removeEventListener('click', this.closeOnClickOutside);
        setTimeout(() => {
          this.isSearchViewOpen = false;
        }, this.headerState.speed);
      }
    },
  },
  methods: {
    closeOnClickOutside() {
      if (this.isSearchOpen && !this.isMouseInView && (window?.innerWidth || 0) > 767) {
        this.isSearchOpen = false;
      }
    },
  },
};
</script>

<template>
  <div class="search-view-desktop" ref="searchViewDesktop">
    <div
      @mouseenter="isMouseInView = true"
      @mouseleave="isMouseInView = false"
      class="search-view-desktop__wrap"
      :class="{ 'search-view-desktop__wrap--open': isSearchOpen }"
    >
      <div class="search-view-desktop__input" v-if="isSearchViewOpen">
        <search-view-input />
      </div>

      <div
        class="search-view-desktop__content"
        v-if="isSearchViewOpen && searchResult"
      >
        <search-view-desktop-results :search-result="searchResult" />
      </div>
    </div>
  </div>
</template>

<style>
.search-view-desktop {
  /* z-index: 10;
  position: absolute;
  top: 66px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  pointer-events: all; */
  width: 100%;
}

.search-view-desktop__wrap {
  pointer-events: none;
  background-color: var(--color-neutral-00);
  opacity: 0;
  width: 100vw;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 150ms;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-view-desktop__wrap--open {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0);
  box-shadow: 0px 5px 16px rgb(26 26 26 / 5%);
}

.search-view-desktop__input {
  padding: 1rem 0 2rem;
}

.search-view-desktop__content {
  max-width: var(--layout-maxWidth);
  margin: 0 auto;
  position: relative;
  width: 100%;
}

@media (--phone) {
  .search-view-desktop__wrap {
    display: none;
  }
}
</style>
