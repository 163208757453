import { computed, inject, ref } from 'vue';
import { invokeKey } from '@drapejs/core';
import { getCompareProductData } from '@/commands/compare-products';

const sessionStorageKey = 'compare-products';

const products = ref<string[]>([]);

if (typeof sessionStorage !== 'undefined') {
  const val = sessionStorage.getItem(sessionStorageKey);
  if (val) {
    const val2 = JSON.parse(val) as string[];
    if (val2) {
      products.value = val2;
    }
  }
}

export const productCount = computed(() => products.value.length);

const productData = ref<any>({});
const comparePrintUrl = ref<string | null>(null);
const opened = ref(false);

const loadedProductsData = computed(() => products.value.map((p) => productData.value[p]).filter((p) => p));

export default function useCompareProducts() {
  const invoke = inject(invokeKey);

  async function fetchProducts() {
    const missingProductData = products.value.filter((p) => !productData.value[p]);

    if (missingProductData.length > 0) {
      const response = await invoke!(getCompareProductData, {
        url: window.location.href,
        baseProductNumbers: missingProductData,
      });

      const data = response.session.compareProductData;
      data.products.forEach((data: any) => {
        productData.value[data.baseProductNumber] = data;
      });
      comparePrintUrl.value = data.comparePrintUrl;
    }
  }

  function add(baseProductNumbers: string | string[]) {
    let newProducts = baseProductNumbers;
    if (typeof newProducts === 'string') newProducts = [newProducts];

    let scheduleFetch = false;

    newProducts.forEach((product) => {
      const index = products.value.indexOf(product);

      if (index === -1) {
        products.value.push(product);
        scheduleFetch = true;
      }
    });
    
    if (scheduleFetch) {
      fetchProducts();
    }

    updateLocalStorage();
  }

  function replaceProducts(baseProductNumbers: string[]) {
    products.value = baseProductNumbers;
    fetchProducts();
    updateLocalStorage();
  }

  function remove(baseProductNumber: string) {
    const index = products.value.indexOf(baseProductNumber);

    if (index !== -1) {
      products.value.splice(index, 1);
    }

    if (!products.value.length) {
      opened.value = false;
    }

    updateLocalStorage();
  }

  function updateLocalStorage() {
    if (products.value.length === 0) {
      sessionStorage.removeItem(sessionStorageKey);
    } else {
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(products.value));
    }
  }

  function clear() {
    products.value = [];
    updateLocalStorage();
  }

  function toggleOpen() {
    opened.value = !opened.value;
  }

  return {
    add,
    remove,
    replaceProducts,
    clear,
    toggleOpen,
    fetchProducts,
    comparePrintUrl,
    products,
    loadedProductsData,
    opened,
  };
}
