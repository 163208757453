import { defineReceiver, defineCommand } from '@drapejs/invoker';
import { request, query, sales } from '@distancify/drapejs-litium';
import { gql } from 'graphql-request';

export const commands = {
  applyVoucherCode: defineCommand<{
    url: string;
    voucherCode: string;
    cacheKey?: string;
  }>('applyVoucherCode'),
  setAddressFromKlarna: defineCommand<{
    url: string;
    country: string;
    zip: string;
    email: string;
    cacheKey?: string;
  }>('setAddressFromKlarna'),
  getKlarnaOrder: defineCommand<{
    url: string;
    transactionNumber: string;
    accountId: string;
    cacheKey?: string;
  }>('getKlarnaOrder'),
  clearCart: defineCommand<{
    url: string;
  }>('clearCart'),
  setNewsletterSubscriptionStatus: defineCommand<{
    subscribeToNewsletter: string;
    url: string;
  }>('setNewsletterSubscriptionStatus'), 
  setKlarnaCustomerType: defineCommand<{
    customerType: string;
    cacheKey?: string;
  }>('setKlarnaCustomerType'),
  executeExternalPayment: defineCommand<{
    url: string;
  }>('executeExternalPayment'),
  refreshCart: defineCommand<{
    url: string;
  }>('refreshCart'),
  subscribeToVoyadoMarketingPromotions: defineCommand<{
    url: string;
    email: string;
  }>('subscribeToVoyadoMarketingPromotions'),
  resetCartToAvailableStock: defineCommand<{
    url: string;
  }>('resetCartToAvailableStock'),
};

export const receivers = {
  applyVoucherCode: defineReceiver(
    commands.applyVoucherCode,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation applyVoucherCode($url: String!, $discountCode: String!) {
              session(url: $url) {
                setDiscountCode(discountCode: $discountCode) {
                  error
                  cart {
                    ...CartFields
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
          discountCode: command.voucherCode || '',
        }
      );

      await this.cache.setItem('__cart', result.session.setDiscountCode.cart);

      return result.session.setDiscountCode;
    },
    'litium'
  ),
  setAddressFromKlarna: defineReceiver(
    commands.setAddressFromKlarna,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation setAddressFromKlarna($url: String!, $country: String!, $zip: String!, $email: String!) {
              session(url: $url) {
                setAddressFromKlarna(country: $country, zip: $zip, email: $email) {
                  error
                  marketingSubscriberId
                  cart {
                    ...CartFields
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
          country: command.country || '',
          zip: command.zip || '',
          email: command.email || '',
        }
      );

      await this.cache.setItem('__cart', result.session.setAddressFromKlarna.cart);
      await this.cache.setItem('__marketingSubcriberId', result.session.setAddressFromKlarna.marketingSubscriberId);

      return result.session.setAddressFromKlarna;
    },
    'litium'
  ),
  getKlarnaOrder: defineReceiver(
    commands.getKlarnaOrder,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            query getKlarnaOrder($url: String!, $transactionNumber: String!, $accountId: String!) {
              session(url: $url) {
                klarnaReceipt(transactionNumber: $transactionNumber, accountId: $accountId) {
                  htmlSnippet
                  receipt {
                    totalDeliveryCost
                    totalDiscount
                    totalOrderRow
                    totalVat
                    customerNumber
                    customerEmail
                    consent
                    grandTotal
                    paymentMethod
                    externalOrderId
                    cartOrderId
                    rows {
                      ...RowFields
                    }
                  }
                }
              }
            }
          `,
          sales.withRowFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
          transactionNumber: command.transactionNumber || '',
          accountId: command.accountId || '',
        }
      );

      return result.session.klarnaReceipt;
    },
    'litium'
  ),
  clearCart: defineReceiver(
    commands.clearCart,
    async function (command) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation clearCart($url: String!) {
              session(url: $url) {
                clearCart {
                  error
                  cart {
                    ...CartFields
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: command.url,
        }
      );
      await this.cache.setItem('__cart', result.session.clearCart.cart);
      return result.session.clearCart;
    },
    'litium'
  ),
  setNewsletterSubscriptionStatus: defineReceiver(
    commands.setNewsletterSubscriptionStatus,
    async function (command) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation setNewsletterSubscriptionStatus($url: String!, $subscribeToNewsletter: Boolean!) {
              session(url: $url) {
                setNewsletterSubscriptionStatus(subscribeToNewsletter: $subscribeToNewsletter) {
                  error
                  marketingSubscriberId
                  cart {
                    ...CartFields
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: command.url,
          subscribeToNewsletter: command.subscribeToNewsletter
        }
      );
      await this.cache.setItem('__cart', result.session.setNewsletterSubscriptionStatus.cart);
      await this.cache.setItem('__marketingSubcriberId', result.session.setNewsletterSubscriptionStatus.marketingSubscriberId);

      return result.session.setNewsletterSubscriptionStatus;
    },
    'litium'
  ),  
  setKlarnaCustomerType: defineReceiver(
    commands.setKlarnaCustomerType,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation setKlarnaCustomerType($url: String!, $customerType: String) {
              session(url: $url) {
                setKlarnaCustomerType(customerType: $customerType) {
                  error
                  cart {
                    ...CartFields
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
          customerType: command.customerType,
        }
      );
      await this.cache.setItem('__cart', result.session.setKlarnaCustomerType.cart);
      return result.session.setKlarnaCustomerType;
    },
    'litium'
  ),
  refreshCart: defineReceiver(
    commands.refreshCart,
    async function (command) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation refreshCart($url: String!) {
              session(url: $url) {
                refreshCart {
                  error
                  cart {
                    ...CartFields
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: command.url,
        }
      );
      await this.cache.setItem('__cart', result.session.refreshCart.cart);
      return result.session.refreshCart;
    },
    'litium'
  ),
  subscribeToVoyadoMarketingPromotions: defineReceiver(
    commands.subscribeToVoyadoMarketingPromotions,
    async function (command) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation subscribeToVoyadoMarketingPromotions($url: String!, $email: String!) {
              session(url: $url) {
                subscribeToVoyadoMarketingPromotions(email: $email, url: $url) {
                  error
                }
              }
            }
          `
        ),
        {
          url: command.url,
          email: command.email,
        }
      );
      return result.session.subscribeToVoyadoMarketingPromotions;
    },
    'litium'
  ),
  resetCartToAvailableStock: defineReceiver(
    commands.resetCartToAvailableStock,
    async function (command) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation resetCartToAvailableStock($url: String!) {
              session(url: $url) {
                resetCartToAvailableStock {
                  error
                  cart {
                    ...CartFields
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: command.url,
        }
      );
      await this.cache.setItem('__cart', result.session.resetCartToAvailableStock.cart);
      return result.session.resetCartToAvailableStock;
    },
    'litium'
  ),
};
