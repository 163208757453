import { query, request, sales } from '@distancify/drapejs-litium';
import { defineCommand, defineReceiver } from '@drapejs/invoker';
import { gql } from 'graphql-request';

const getContactId = gql`
  query getContactId($url: String!, $eclub: String!) {
    session(url: $url) {
      voyadoContactId(eclub: $eclub)
    }
  }
`;

const setCartRef = gql`
  mutation setCartRef($url: String!, $cartRef: String!) {
    session(url: $url) {
      setVoyadoCartRef(cartRef: $cartRef) {
        error
        cart {
          ...CartFields
        }
      }
    }
  }
`;

export const commands = {
  getContactId: defineCommand<{
    url: string;
    eclub: string;
  }>('getContactId'),
  setCartRef: defineCommand<{
    url: string;
    cartRef: string;
  }>('setCartRef'),
};

export const receivers = {
  getContactId: defineReceiver(
    commands.getContactId,
    async function (command, data) {
      try {
        const result = await request(this.cache, query(getContactId), {
          ...command,
        });
        return { contactId: result.session.voyadoContactId };
      } catch {
        return undefined;
      }
    },
    'litium',
  ),
  setCartRef: defineReceiver(
    commands.setCartRef,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(setCartRef, ...sales.withCartFields()),
        {
          ...command,
        },
      );

      await this.cache.setItem('__cart', result.session.setVoyadoCartRef.cart);
    },
    'litium',
  ),
};
