import { inject, computed, ref } from 'vue';
import { fetchPageBuilder, invokeKey } from '@drapejs/core';
import { countrySwitcherKey } from '@/keys';
import * as user from '../commands/user';
import {
  buildChannelSwitchUrlPathWithQuery,
  setPrefferedCountryCookie,
} from '../utils';
import { cartKey, channelKey } from './../keys';

import useContext from '@/composables/useContext';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default function useCountrySwitch() {
  const { route } = useContext();
  const { websiteText } = useWebsiteTexts();

  const invoke = inject(invokeKey);
  const channel = inject(channelKey, ref(null));
  const cart = inject(cartKey, ref(null));

  const countrySwitcher = inject(countrySwitcherKey, ref(null));

  const availableCountries = computed(() => {
    return countrySwitcher?.value?.availableCountries;
  });

  const geoIpCountryCode = computed(
    () => countrySwitcher?.value?.geoIpCountryCode || ''
  );

  const activeCountryOption = computed(() => {
    const cartCountryId = cart?.value?.countryId;
    const channelId = channel?.value?.systemId;
    return (availableCountries?.value || []).find(
      (c) => c.countryId == cartCountryId && c.channelSystemId == channelId
    );
  });

  const changeCountryError = computed(
    () =>
      websiteText('global__change_country_error').value ||
      'global__change_country_error'
  );

  const countryOptions = computed(() => {
    const channels = [...(availableCountries?.value || [])];
    channels.forEach((c) => {
      c.value = c?.countryId;
      c.text = (c.englishUsName || '').trim();
    });
    return channels;
  });

  async function setCountry(countryId = '') {
    try {
      const request: any = fetchPageBuilder(
        route.protocol,
        route.host,
        route.pathname,
        {
          ...route.query,
        },
        ''
      );
      request.countryId = countryId;

      const result = await invoke?.(user.commands.setCountry, request);
      if (countryId) {
        setPrefferedCountryCookie(countryId);
      }
      return result;
    } catch (err) {}
  }

  function buildRedirectUrl(url) {
    if (!url) return '';
    const q = route.query || {};
    return buildChannelSwitchUrlPathWithQuery(url, q);
  }

  return {
    availableCountries,
    activeCountryOption,
    countryOptions,
    geoIpCountryCode,
    setCountry,
    buildRedirectUrl,
    changeCountryError,
  };
}
