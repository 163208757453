<script>

export default {
    props: {
        info: {
            type: Object,
            default: null,
        }
    },
    computed: {
        isInfoValid() {
            if (!this.info) return false;
            return this.info.imageId !== '00000000-0000-0000-0000-000000000000';
        }
    },
    methods: {
        toScaleImage(id) {
            return this.$toLitiumMediaUrl(id, { maxHeight: 60 });
        }
    }
}
</script>

<template>
    <div>
        <div v-if="isInfoValid" class="compare-view-visual-info__itemHeadingCompare">{{info.label}}</div>
        <img v-if="isInfoValid" class="compare-view-visual-info__image" :src="toScaleImage(info.imageId)" />
        <div v-if="isInfoValid" v-html="info.description"></div>
    </div>
</template>


<style>
.compare-view-visual-info {
    margin: 1rem auto
}

.compare-view-visual-info__item {
    margin: 2rem auto;
    text-align: center;
    color: var(--color-black);
}

.compare-view-visual-info__itemHeading {
    font-size: 25px;    
    font-family: var(--font-header);    
}

.compare-view-visual-info__itemHeadingCompare {
    font-size: 21px;
    font-family: var(--font-header);
}

.compare-view-visual-info__itemInfo {
    display: inline-block;
    background-image: url(/UI/img/icon_info.svg);
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 8px;
    width: 24px;
    height: 22px;
    margin: 8px 0 0;
    cursor: pointer;
}

.compare-view-visual-info__image {
    margin: 1rem 0;
    height: 8rem;

    @media (--tabletAndDesktop) {
        height: 6rem;
    }
}

.compare-view-visual-info__popup {    
    position: fixed;
    z-index: 300;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
}

.compare-view-visual-info__popupHidden {
    display: none;
}

.compare-view-visual-info__popupContent {
    margin: 15% auto;
    padding: 1rem;
    border: 1px solid var(--color-grey500);
    max-width: 520px;
    background-color: var(--color-white);
    color: var(--color-black);
}

.compare-view-visual-info__popupContent b, .compare-view-visual-info__popupContent strong {
    font-weight: bold;
}

.compare-view-visual-info__popupHeading {
    font-family: var(--font-header);
    font-size: 25px;
    text-align: left;
    line-height: 45px;
}

.compare-view-visual-info__popupDescription {    
    text-align: left;
}

.compare-view-visual-info__popupClose {
    float: right;
    font-family: var(--font-body);
    font-size: 45px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
}
</style>