<script>
import { OptionsComponent } from '@drapejs/core';
import ModalContainer from '../ModalContainer.vue';
import SearchViewInput from './SearchViewInput.vue';
import SearchViewPhoneResults from './SearchViewPhoneResults.vue';
import { searchResultKey, isSearchOpenKey } from '@/keys';

export default {
  extends: OptionsComponent,
  components: {
    ModalContainer,
    SearchViewInput,
    SearchViewPhoneResults,
  },
  inject: {
    searchResult: searchResultKey,
    isSearchOpen: isSearchOpenKey,
  },
  data() {
    return {
      isMounted: false,
    };
  },
  mounted() {
    this.isMounted = true;
  },
  watch: {
    isSearchOpen(val) {
      if (val) {
        this.$nextTick(() => this.$mitt.emit('focus-search-input'));
      }
    },
  },
  methods: {
    closeSearchView() {
      this.$mitt.emit('close-search');
    },
  },
};
</script>

<template>
  <template v-if="isMounted">
    <teleport to="body">
      <modal-container
        position="right"
        :show-exit-button="true"
        v-if="isSearchOpen"
        class="search-view-phone__modal"
        @close-modal="closeSearchView"
      >
        <div class="search-view-phone">
          <search-view-input class="search-view-phone__input" />
          <div class="search-view-phone__content" ref="searchViewPhoneContent">
            <search-view-phone-results :search-result="searchResult" />
          </div>
        </div>
      </modal-container>
    </teleport>
  </template>
</template>

<style>
.search-view-phone__input {
  margin-top: 2rem;
}

@media (--tabletAndDesktop) {
  .search-view-phone__modal {
    display: none;
  }
}
</style>
