import Vue, { createSSRApp, defineAsyncComponent } from 'vue'
import App from './App.vue'
import drapejs from '@drapejs/core'
import { plugin as Litium } from '@distancify/drapejs-litium'
import { OptionsComponent } from '@drapejs/core'
import Worker from 'worker-loader!./web-worker'

import {
  emitterKey,
  channelKey,
  cartKey,
  countrySwitcherKey,
  userKey,
} from './keys';

import { buildUrlPathWithQuery } from "./utils"
import * as config from './config'

import './style/index.css'

const tokenRegex = /\{{(.+?)\}}/gi;

export default function (workerFactory: () => Worker) {
  const create = <any>createSSRApp;

  const app = create(App)
    .use(drapejs, {
      pageComponents: {
        ExternalPayment: defineAsyncComponent(() => import('./pages/ExternalPayment.vue')),
        ComparePrint: defineAsyncComponent(() => import('./pages/ComparePrint.vue')),
        Product: defineAsyncComponent(() => import('./pages/Product.vue')),
        Login: defineAsyncComponent(() => import('./pages/Login.vue')),
        Category: defineAsyncComponent(() => import('./pages/Category.vue')),
        ContentPage: defineAsyncComponent(() => import('./pages/ContentPage.vue')),
        Measurements: defineAsyncComponent(() => import('./pages/FullWidth.vue')),
        FullWidth: defineAsyncComponent(() => import('./pages/FullWidth.vue')),
        NotFound: defineAsyncComponent(() => import('./pages/NotFound.vue')),
        Centered: defineAsyncComponent(() => import('./pages/Centered.vue')),
        VoyadoConsent: defineAsyncComponent(() => import('./pages/VoyadoConsent.vue')),
        Media: defineAsyncComponent(() => import('./pages/Media.vue')),
        EasycomReturn: defineAsyncComponent(() => import('./pages/EasycomReturn.vue')),
        CheckoutB2C: defineAsyncComponent(() => import('./pages/CheckoutB2C.vue')),
        Receipt: defineAsyncComponent(() => import('./pages/Receipt.vue')),
        InstagramCollectionPage: defineAsyncComponent(() => import('./pages/InstagramCollectionPage.vue')),
        ProductBlocksContainer: defineAsyncComponent(() => import('./pages/ProductBlocksContainer.vue')),
      },
      workerFactory,
    })
    .use(Litium as Vue.Plugin, {
      baseUrl: config.litiumBaseUrl,
      blockComponents: {
        // Your block component names should match your block IDs in Litium
        Hero: defineAsyncComponent(() => import('./blocks/Hero')),
        Ribbon: defineAsyncComponent(() => import('./blocks/Ribbon')),
        TextWithTwoImages: defineAsyncComponent(() => import('./blocks/TextWithTwoImages')),
        ContentSlider: defineAsyncComponent(() => import('./blocks/ContentSlider')),
        TextBanners: defineAsyncComponent(() => import('./blocks/TextBanners')),
        Instagram: defineAsyncComponent(() => import('./blocks/Instagram')),
        TextColumns: defineAsyncComponent(() => import('./blocks/TextColumns')),
        TextBlock: defineAsyncComponent(() => import('./blocks/TextBlock')),
        TextWithImage: defineAsyncComponent(() => import('./blocks/TextWithImage')),
        Quote: defineAsyncComponent(() => import('./blocks/Quote')),
        Html: defineAsyncComponent(() => import('./blocks/HtmlBlock')),
        LinksBlock: defineAsyncComponent(() => import('./blocks/LinksBlock')),
        HorizontalRuler: defineAsyncComponent(() => import('./blocks/HorizontalRuler')),
        Banner: defineAsyncComponent(() => import('./blocks/Banner')),
        BannerGrid: defineAsyncComponent(() => import('./blocks/BannerGrid')),
        Newsletter: defineAsyncComponent(() => import('./blocks/Newsletter')),
        MediaHighlight: defineAsyncComponent(() => import('./blocks/MediaHighlight')),
        MediaWithProducts: defineAsyncComponent(() => import('./blocks/MediaWithProducts.vue')),
        NewsletterVoyado: defineAsyncComponent(() => import('./blocks/Newsletter')),
        MarketingSubscriber: defineAsyncComponent(() => import('./blocks/MarketingSubscriber')),
        DynamicProducts: defineAsyncComponent(() => import('./blocks/DynamicProducts.vue')),
        ProductsCarousel: defineAsyncComponent(() => import('./blocks/ProductsCarousel.vue')),
        RecentlyVisitedProducts: defineAsyncComponent(() => import('./blocks/RecentlyVisitedProducts.vue')),
        RelatedProducts: defineAsyncComponent(() => import('./blocks/RelatedProducts.vue')),
        ProductFunctions: defineAsyncComponent(() => import('./blocks/ProductFunctions.vue')),
        ProductReviewsBlock: defineAsyncComponent(() => import('./blocks/ProductReviews.vue')),
        ProductGrid: defineAsyncComponent(() => import('./blocks/ProductGrid')),
        UGCMediaGrid: defineAsyncComponent(() => import('./blocks/UGCMediaGrid')),
        CookieDeclaration: defineAsyncComponent(() => import('./blocks/CookieDeclaration')),
        WhitespaceBlock: defineAsyncComponent(() => import('./blocks/WhitespaceBlock')),
      },
    })
    .mixin({
      extends: OptionsComponent,
      inject: {
        $emitter: {
          from: emitterKey,
          default: {},
        },
        $cartReactive: {
          from: cartKey,
          default: {
            value: null,
          },
        },
        $channelReactive: {
          from: channelKey,
          default: {
            value: null,
          },
        },
        $metaProductReactive: {
          default: {},
        },
        $userReactive: {
          from: userKey,
          default: {
            value: null,
          },
        },
        $countrySwitcherReactive: {
          from: countrySwitcherKey,
          default: {
            value: null,
          },
        },
      },
      data: () => ({
        width: 0,
      }),
      computed: {
        $mitt() {
          return this.$emitter;
        },
        $cart() {
          return this.$cartReactive || {};
        },
        $channel() {
          return this.$channelReactive || {};
        },
        $metaProduct() {
          return this.$metaProductReactive || {};
        },
        $countrySwitcher() {
          return this.$countrySwitcherReactive || [];
        },
        $globalFields() {
          return this.$channel?.website?.fields || {};
        },
        $user() {
          if (!this.$userReactive) {
            return {};
          }

          const user = { ...(this.$userReactive?.data || {}) };
          user.isAuthenticated = this.$userReactive?.isAuthenticated || false;

          return user;
        },
        $isPhone() {
          return this.width < 768;
        },
      } as any,
      methods: {
        $formatPrice(value: number, decimals?: number, locale?: string, currencyId?: string) {
          if (!decimals) {
            decimals = 2;
          }
          if (value !== 0 && !value) {
            return '';
          }
          if (!this.$cart) {
            return value;
          }
          return new Intl.NumberFormat(locale || this.$channel?.locale || 'sv-SE', {
            style: 'currency',
            currency: currencyId || this.$cart.currency?.id || 'SEK',
            maximumFractionDigits: decimals,
            minimumFractionDigits: 0,
          }).format(value);
        },
        $toVariantUrl(variant: {
          url: string;
          fields?: {
            Size?: { name: string; value: string };
            Color?: [{ name: string; value: string }];
          };
        }) {
          function variantQueryParams() {
            let urlSuffix = '';

            const suffixParts = [
              {
                key: 'size',
                value: variant.fields?.Size?.value,
              },
              {
                key: 'color',
                value: (variant.fields?.Color || []).map((c: { name: string; value: string }) => c.value).join(','),
              },
            ].filter((q) => q.value);

            if (suffixParts.length) {
              urlSuffix = suffixParts
                .reduce((p, { key, value }) => {
                  return `${p}${key}=${value}&`;
                }, '?')
                .slice(0, -1);
            }

            return urlSuffix;
          }

          return variant.url + variantQueryParams();
        },
        $isColorVariantMatch(
          color: string,
          variant: {
            fields: {
              Size?: { name: string; value: string };
              Color?: [{ name: string; value: string }];
            };
          }
        ) {
          let variantColor = variant.fields.Color?.map((c) => c.value) || [];
          let colorCopy = color.split(',');
          if (variantColor.length !== colorCopy.length) return false;

          variantColor.sort();
          colorCopy.sort();

          for (let i = 0; i < variantColor.length; i++) {
            if (variantColor[i] !== colorCopy[i]) return false;
          }

          return true;
        },
        $variantToColorString(variant?: {
          fields: {
            Size?: { name: string; value: string };
            Color?: [{ name: string; value: string }];
          };
        }) {
          const color = variant?.fields.Color?.map((c) => c.value).sort() || [];
          return color.join(',');
        },
        $replaceTokens(text, args) {
          if (!text) return text;

          let formatted = text;

          function getValueInContext(ctx, path) {
            const key = path.shift();
            const value = ctx[key];
            if (!value) return null;
            if (path.length) return getValueInContext(value, path);
            return value;
          }

          function replace(placeholders) {
            if (!placeholders) return;
            formatted = formatted.replace(tokenRegex, (_, p1) => {
              return getValueInContext(placeholders, p1.toLowerCase().split('.')) || '';
            });
          }

          replace(args);

          return formatted;
        },
        $toBlock(template: string) {
          if (!template) return '';

          return `block-${template.toLowerCase()}`;
        },
        $navigateToLoginPage(query = null) {
          const search = query || this.$route.query || {};
          const completeQuery = {
            ...search,
            redirect: encodeURIComponent(this.$route.pathname || ''),
          };

          if (this.$channel?.loginPageUrl) {
            const loginUrl = buildUrlPathWithQuery(this.$channel.loginPageUrl, completeQuery);
            this.$navigate(loginUrl);
          } else if (this.$channel?.rootPath) {
            const rootPageUrl = buildUrlPathWithQuery(this.$channel.rootPath, completeQuery);
            this.$navigate(rootPageUrl);
          }
        },
        setWidth() {
          this.width = window?.innerWidth || 0;
        },
      } as any,
      mounted() {
        this.setWidth();
        window.addEventListener('resize', this.setWidth);
      },
      beforeUnmount() {
        window.removeEventListener('resize', this.setWidth);
      },
    });

  return app;
}
