import { defineReceiver, defineCommand } from '@drapejs/invoker';
import { gql } from 'graphql-request';
import { request, query, batch, sales } from '@distancify/drapejs-litium';
import { fetchPage } from '@drapejs/core';

export const userFields = gql`
  fragment UserFields on User {
    isAuthenticated
    companyName
    organization {
      organizationName
      organizationNo
      organizationId
      name
      address
      address2
      postCode
      city
      country
      countries {
        value
        text
      }
      shippingAddresses {
        no
        code
        systemId
        name
        address
        address2
        postCode
        city
        country
      }
      contacts {
        no
        code
        systemId
        firstName
        lastName
        email
        phone
      }
    }
    organizations {
      organizationName
      organizationId
    }
    person {
      firstName
      lastName
      email
      emailUnverified
      phone
    }
  }
`;

export const commands = {
  setCountry: defineCommand<{
    url: string;
    countryId: string;
  }>('setCountry'),
};

export const receivers = {
  setCountry: defineReceiver(
    commands.setCountry,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation setCountry($url: String!, $countryId: String) {
              session(url: $url) {
                setCountry(countryId: $countryId) {
                  error
                  redirectUrl
                  cart {
                    ...CartFields
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
          countryId: command.countryId,
        }
      );
      const { error, cart } = result.session.setCountry;
      if (error === 'NONE') {
        await this.cache.setItem('__cart', cart);
      }

      return result.session.setCountry;
    },
    'litium'
  ),
};

export const schedule = defineReceiver(fetchPage, async function (command, data) {
  const marketingSubscriberId = command.query?.memberId || await this.cache.getItem('__marketingSubcriberId') || ''

  await this.invoke(batch.scheduleQuery, {
    query: gql`
    user {
      isAuthenticated
      isMarketingSubscriber(marketingSubscriberId: $marketingSubscriberId)
    }
  `,
    cacheKey: '__user',
    variables: {
      marketingSubscriberId: {
        value: marketingSubscriberId,
        type: 'String',
      },
    },
  });

  return data;
});

export const process = defineReceiver(fetchPage, async function (command, data) {
  const user = await this.cache.getItem('__user');
  if (user.isMarketingSubscriber && command.query?.memberId) {
    await this.cache.setItem('__marketingSubcriberId', command.query.memberId);
  }
  return data;
});
