/* eslint-disable @typescript-eslint/camelcase */
import { inject, ref } from 'vue';

import { invokeKey, routeKey, getItemKey } from '@drapejs/core';
import { sales } from '@distancify/drapejs-litium';
import { useGA4 } from '@distancify-storefront/tracking-gtm';

import * as localSales from '../commands/sales';

const isCartOpen = ref(false);
const ga4 = useGA4({
  useLegacyEnhancedECommerce: true,
});

export function useCartFunctions() {
  
  const $invoke = inject(invokeKey);
  const $route = inject(routeKey);
  const $getItem = inject(getItemKey);

  return {
    isCartOpen,
    addItemToCart: async function(variantData: VariantData) {
    
      var $cart = await $getItem?.('__cart');
      var beforeRow = Object.assign({}, $cart?.rows?.find((r: { articleNumber: string }) => r.articleNumber == variantData.articleNumber));
      
      const request = <any>{};

      request.articleNumber = variantData.articleNumber;
      request.quantity = variantData.quantity;
      request.url = `${$route?.protocol}//${$route?.host}${$route?.pathname}`;

      const { row, error } = await $invoke?.(
        sales.addToCart,
        request
      );
      
      $cart = await $getItem?.('__cart');
      var afterRow = Object.assign({}, $cart.rows?.find((r: { articleNumber: string }) => r.articleNumber == variantData.articleNumber));
      
      reportCartToDataLayer($cart.currency.id, afterRow, beforeRow);

      return { row, error };
    },

    setQuantityOnRow: async function(rowId: string, quantity: number) {
      var $cart = await $getItem?.('__cart');
      var beforeRow = Object.assign({}, $cart?.rows?.find((r: { id: string }) => r.id == rowId));

      const request = <any>{};

      request.rowId = rowId;
      request.quantity = quantity;
      request.url = `${$route?.protocol}//${$route?.host}${$route?.pathname}`;

      const result = await $invoke?.(sales.setRowQuantity, request);

      $cart = await $getItem?.('__cart');
      var afterRow = Object.assign({}, $cart.rows?.find((r: { id: string }) => r.id == rowId));

      reportCartToDataLayer($cart.currency.id, afterRow, beforeRow);

      return result;
    },
    toggleNewsletterSubscribtion: async function(subscribeToNewsletter){
      const request = <any>{};

      request.subscribeToNewsletter = subscribeToNewsletter;
      request.url = `${$route?.protocol}//${$route?.host}${$route?.pathname}`;

      const result = await $invoke?.(localSales.commands.setNewsletterSubscriptionStatus, request);

      return result;
    }
  }
}

declare var dataLayer: any;

function reportCartToDataLayer(currency: string, afterRow: any, beforeRow: any) {
  
  if (typeof dataLayer === 'undefined') {
    return;
  }

  const before = beforeRow?.quantity;
  const after = afterRow?.quantity;

  let diff = 0;
  if (before && !after) {
    diff = before * -1;
  } else if (before && after) {
    diff = after - before;
  } else if (!before && afterRow) {
    diff = after;
  }

  if (diff > 0) {
    ga4.addToCart(
      currency,
      [buildItemForGa(afterRow, diff)],
    );
  }
  else if (diff < 0) {
    ga4.removeFromCart(
      currency,
      [buildItemForGa(beforeRow, diff * -1)],
    );
  }
}

function buildItemForGa(row: any, quantity: number) {
  const item: any = {
    item_id: row.articleNumber,
    item_name: row.variantName,
    item_color: row.color,
    item_color_id: row.baseProductId,
    item_variant: row.size,
    discount: row.unitListPrice - row.unitPrice,
    price: row.unitPrice,
    quantity,
  };

  const categoryPath = row.googleAnalyticsCategoryPath || [];
  for (let i = 0; i < Math.min(5, categoryPath.length); i += 1) {
    if (i === 0) {
      item.item_category = categoryPath[i];
    } else {
      item[`item_category${(i + 1)}`] = categoryPath[i];
    }
  }

  return item;
}

interface VariantData {
  articleNumber: string;
  quantity: number
}