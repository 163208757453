<script>
  import ProductCard from '../ProductCard';
  import useWebsiteTexts from '@/composables/useWebsiteTexts';

  import { searchMixin } from './search';

  import ExpandableList from './ExpandableList.vue';

  export default {
    components: {
      ProductCard,
      ExpandableList,
    },
    mixins: [searchMixin],
    setup() {
      const { websiteText } = useWebsiteTexts();

      return {
        websiteText,
      };
    },
  };
</script>

<template>
  <div
    class="search-view-phone-results"
    v-if="searchResult && hasAnySearchResults">
    <div class="search-view-phone-results__content">
      <div
        class="search-view-phone-results__facets"
        v-if="facetSuggestions && facetSuggestions.length > 0">
        <div
          v-for="facet in facetSuggestions"
          :key="facet.field"
          class="search-view-phone-results__facet-suggestion">
          <div
            v-for="facetValue in facet.suggestedValues"
            :key="facetValue.value"
            class="search-view-phone-results__facet-value-suggestion"
            @click="
              $mitt.emit('apply-facet-suggestion', {
                facetId: facet.field,
                facetValueId: facetValue.value,
              });
              $mitt.emit('close-search-bar');
            ">
            {{ facetValue.label }}
          </div>
        </div>
      </div>

      <div class="search-view-phone-results__scrollable-content">
        <div class="search-view-phone-results__expandable-lists">
          <ExpandableList
            v-if="categoryHits"
            :caption="websiteText('search__category_results_title').value || 'search__category_results_title'"
            :items="categories"
            :getItemName="getCategoryName" />

          <ExpandableList
            v-if="pageHits"
            :caption="websiteText('search__page_results_title').value || 'search__page_results_title'"
            :items="pages"
            :getItemName="(page) => page.pageName" />
        </div>
        <div
          class="search-view-phone-results__all-product-hits"
          v-if="productHits"
          @click="performFullSearch()">
          {{ productHitsLabel }}
        </div>
        <div class="search-view-phone-results__products">
          <product-card
            v-for="product in products"
            :key="product.articleNumber"
            :product="product"
            class="search-view-phone-results__product"
            @click="
              $mitt.emit('search-result-clicked');
              $mitt.emit('close-search-bar');
            " />
        </div>
      </div>
    </div>
  </div>
  <div
    class="jsPhoneNoSearchResults search-view-phone-results__no-result"
    v-if="searchResult && !hasAnySearchResults">
    <div>
      {{ websiteText('search__no_hits').value }}
    </div>
  </div>
</template>

<style>
  .search-view-phone-results {
    background-color: white;
    padding-top: 0.5rem;
    height: 100%;
    padding-bottom: 3rem;
  }
  .search-view-phone-results__content {
    height: 100%;
  }

  .search-view-phone-results__scrollable-content {
    height: 100%;
    overflow-y: auto;
  }

  .search-view-phone-results__page {
    padding-right: 0.5rem;
  }

  .search-view-phone-results__all-product-hits {
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    margin: 0.15rem 0.75rem 1rem;
    display: inline-block;
  }

  .search-view-phone-results__facets {
    display: flex;
    overflow-x: auto;
    font-size: 14px;
    margin: 0 0 0.85rem 0.75rem;
  }

  .search-view-phone-results__facets {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .search-view-phone-results__facets::-webkit-scrollbar {
    display: none;
  }

  .search-view-phone-results__facet-suggestion {
    display: flex;
  }

  .search-view-phone-results__facet-value-suggestion {
    font-family: var(--font-proxima-nova-regular);
    color: white;
    border: 1px solid black;
    background-color: var(--color-neutral-100);
    padding: 9px 20px;
    margin-right: 0.5rem;
    cursor: pointer;
    white-space: nowrap;
  }

  .search-view-phone-results__expandable-lists {
    margin: 0 0.75rem;
    padding-top: 1.2rem;
  }

  .search-view-phone-results__page-suggestions {
    padding: 0.35rem 0 0.35rem;
  }

  .search-view-phone-results__page-suggestion {
    font-size: 16px;
    font-family: var(--font-proxima-nova-regular);
    line-height: 24px;
    color: #1a1a1a;
    margin-right: 0.75rem;
    text-decoration: none;
  }

  .search-view-phone-results__page-suggestion:hover {
    text-decoration: underline;
  }

  .search-view-phone-results__products {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: auto 0.5rem;
  }

  .search-view-phone-results__product {
    width: 49%;
    margin-bottom: 10px;
  }

  .search-view-phone-results__no-result {
    background-color: white;
    text-align: center;
    padding: 1.25rem 1rem 1.25rem;
    color: var(--color-text-standard);
  }

  .search-view-phone-results__no-result-image {
    padding-top: 1.25rem;
  }

  @media (max-width: 320px) {
    .search-view-phone-results__product {
      width: 100%;
    }
  }

  .search-view-phone-results__product .product-card__variants {
    display: none;
  }

  .search-view-phone-results__product .product-card__name,
  .search-view-phone-results__product .product-card__price {
    font-size: 12px;
  }

  .search-view-phone-results__product .product-card__articleNumber {
    font-size: 10px;
  }
</style>
