<script>
export default {
  emits: ['close-modal', 'navigate-back'],
  props: {
    position: {
      type: String,
      default: '',
    },
    showExitButton: {
      type: Boolean,
      default: true,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    showLeftExitButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    navigateBackward() {
      this.$emit('navigate-back');
    },
  },
};
</script>

<template>
  <div :class="['modal-container__wrapper', `modal-container__wrapper--${this.position}`]">
    <div class="modal-background-cover" @click="closeModal()"></div>
    <div :class="['modal-container', `modal-container--${this.position}`]">
      <div class="modal-container__options-wrapper">
        <button
          v-if="showLeftExitButton"
          class="modal-container__left-exit-button clean-button"
          @click="closeModal()"
        >
          &times;
        </button>
        <img
          v-if="showBackButton"
          class="modal-container__back-button"
          @click="navigateBackward()"
          src="../static/icons/arrow_left_icon.svg"
        />
        <button
          v-if="showExitButton"
          class="modal-container__exit-button clean-button"
          @click="closeModal()"
        >
          &times;
        </button>
      </div>
      <slot />
    </div>
  </div>
</template>

<style>
.modal-container__wrapper {
  z-index: 1000;
}

.modal-background-cover {
  display: none;
  z-index: 200;
}

.modal-container {
  z-index: 200;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow: auto;
  background: white;
  width: 100%;
}

.modal-container__options-wrapper {
  position: relative;
}

.modal-container__left-exit-button {
  cursor: pointer;
  padding-top: 1rem;
  position: absolute;
  left: 1rem;
}

.modal-container__back-button {
  cursor: pointer;
  padding-top: 1rem;
  position: absolute;
  left: 1rem;
  display: flex;
  align-items: center;
}

.modal-container__exit-button {
  cursor: pointer;
  position: absolute;
  right: 11px;
  top: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 45px;
  z-index: 1;
}

@media (min-width: 768px) {
  .modal-container__wrapper {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .modal-background-cover {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  .modal-container__wrapper--center{
    display: flex;
  }

  .modal-container--left {
    left: 0;
  }

  .modal-container--right {
    right: 0;
  }

  .modal-container--center {
    position: relative;
    margin: auto;
    padding: 0;
    background-color: #fff;
    overflow-y: auto;
    max-height: calc(100vh - 1rem);
    min-height: 80px;
  }

  .modal-container {
    max-width: 400px;
    overflow: initial;
  }
}
</style>
