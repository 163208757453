export const searchMixin = {
  props: {
    searchResult: {
      type: Object,
    },
  },
  computed: {
    products() {
      return this.searchResult?.productSearchV2?.products || [];
    },
    productHits() {
      return this.searchResult?.productSearchV2?.hits || 0;
    },

    pages() {
      return this.searchResult?.pageSearch?.pages || [];
    },
    pageHits() {
      return this.searchResult?.pageSearch?.hits || 0;
    },
    facetSuggestions() {
      return this.searchResult?.productSearchV2?.facetSuggestions?.filter((e) => e.suggestedValues?.length > 0) || [];
    },
    categoryHits() {
      return this.searchResult?.categorySearch?.hits || 0;
    },
    categories() {
      return this.searchResult?.categorySearch?.categories || [];
    },
    searchString() {
      return this.searchResult?.searchString || '';
    },
    productHitsLabel() {
      return (
        this.websiteText('search__see_all', {
          hits: this.productHits,
        }).value || 'search__see_all {{hits}}'
      );
    },
    hasAnySearchResults() {
      return (
        this.products.length > 0 ||
        this.facetSuggestions.length > 0 ||
        this.pages.length > 0 ||
        this.categories.length > 0
      );
    },
  },
  methods: {
    performFullSearch() {
      this.$mitt.emit('perform-full-search', this.searchString);
      this.$nextTick(() => {
        this.$mitt.emit('close-search');
      });
    },
    getCategoryName(category) {
      if (!category.departmentName) {
        return category.fields?._name?.value;
      }
      return `${category.fields?._name?.value} (${category.departmentName})`;
    },
  },
};
